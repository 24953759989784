/* General styling for the form container */
.registration-container {
  background: #ffffff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  margin: 10px auto;
}

/* Styling for form headings */
.registration-container h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

/* Styling for form and form elements */
form {
  display: flex;
  flex-direction: column;
}

form div {
  margin-bottom: 15px;
}

form label {
  font-weight: bold;
  color: #333;
  display: block;
  margin-bottom: 5px;
}

form input[type="text"],
form input[type="password"],
form input[type="email"] {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 10px;
}

/* Remove margin for the last input (radio buttons group) */
form div:last-child {
  margin-bottom: 0;
}

/* Styling for radio buttons and their labels */
form input[type="radio"] {
  margin-right: 5px;
}

.role-label {
  margin-right: 20px;
  font-weight: normal;
  color: #333;
}

/* Styling for buttons */
form button,
.paypal-login-btn {
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  color: white;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  text-align: center;
  display: block; /* This will allow the width to be set to 100% */
  width: 100%; /* This will make sure the button stretches to the full width of its parent */
}

form button {
  background-color: #0056b3; /* Adjusted to a common blue color for primary actions */
}

form button:hover {
  background-color: #0056b3; /* Slightly darker on hover for the primary button */
}

.paypal-login-btn {
  background-color: #0056b3; /* PayPal's brand color, a shade of yellow */
  display: flex; /* Keeps the icon aligned with the text */
  align-items: center;
  justify-content: center;
}

.paypal-login-btn:hover {
  background-color: #0056b3; /* Darker shade for the PayPal button hover state */
}

/* Centering the 'or' text */
.or-text {
  text-align: center;
  margin: 10px 0;
}

/* PayPal logo inside the button */
.paypal-login-btn img {
  margin-right: 10px;
  width: 20px; /* Adjust as needed */
}

/* Styling for message */
.message {
  color: #d9534f; /* Error color */
  text-align: center;
}

