/* Apply a basic reset */
html,
body {
  padding: 0;
  margin: 0;
}

.nav-container {
  max-width: 100vw; /* 100% of the viewport width */
  background-color: rgb(250, 135, 145);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 50px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.top-bar {
  width: 100%;
  background-color: rgb(250, 135, 145); /* or any other color you prefer */
}

@keyframes marquee {
  0% { transform: translateX(100%); }
  100% { transform: translateX(-100%); }
}

.coupon-message {
  line-height: 0.5; /* Add line height to center the text vertically */
  text-align: center;
  margin: 0; /* Removes default margin */
  padding: 1px 0; /* Just a tad bit of padding for visual comfort */
  background-color: rgb(
    255,
    233,
    233
  ); /* Lighter shade for the coupon message */
  color: #333; /* Text color */
  font-size: 1em; /* Text size */
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1); /* Subtle text shadow for depth */
  font-weight: bold; /* Make the font bold */
  white-space: nowrap; /* Ensure the text stays in one line */
  overflow: hidden; /* Hide the text outside the container */
  position: relative;
}

/* .coupon-message::before {
  content: attr(data-content);
  position: absolute;
  animation: marquee 10s linear infinite;
  display: inline-block;
  padding: 0 0; 
} */

.nav-logo {
  cursor: pointer;
  font-size: 2.4em;
  font-weight: bold;
  color: #fff; /* Adjusted text color for contrast */
}

.nav-logo,
.nav-menu {
  flex-grow: 1;
}

.nav-logo,
.nav-search,
.nav-menu {
  display: flex;
  align-items: center;
}

.nav-menu {
  justify-content: flex-end; /* Right-align the navigation menu */
  gap: 20px; /* Spacing between links */
}

.nav-search {
  display: flex;
  width: 100%; /* Take the full width of the parent */
  flex-grow: 1; /* Allows search bar to take more space */
  position: relative;
  align-items: center;
  max-width: 600px; /* or your preferred width */
  margin: 0 auto; /* center the search bar */
  background-color: transparent; /* Transparent background */
  border-radius: 50px; /* Fully rounded ends */
  border: 2px solid #fff; /* Light grey border */
  font-size: 1em; /* Text size within the input */
  outline: none; /* Removes the outline on focus */
}

.search-input {
  width: 100%; /* Full width of its parent */
  padding: 10px 20px; /* Padding for the input text */
  font-size: 16px;
  border: none; /* No border */
  outline: none; /* No outline */
  background-color: transparent; /* Transparent background */
  border-radius: 25px;
}

/* No additional styles on focus or active states */
.search-input:focus,
.search-input:active {
  border: none;
  outline: none;
}

/* For resetting browser default styles */
input[type="text"] {
  border: none;
  outline: none;
  background-color: transparent;
}

.search-button {
  padding: 10px;
  border: 2px solid #fff; /* Light grey border */
  border-radius: 0 20px 20px 0; /* Rounded corners on the right side */
  background-color: #fff; /* #f8a72e */
  outline: none;
  position: absolute;
  right: 0;
  cursor: pointer;
}

/* Styling the button on hover for better user feedback */
.search-button:hover {
  background-color: rgb(
    230,
    115,
    125
  ); /* A lighter shade of the main color for hover effect */
}

/* Ensuring the search icon inside the button is vertically centered */
.search-icon {
  vertical-align: middle;
}

.nav-link {
  font-weight: bold;
  color: #fff; /* Adjusted text color for contrast */
  font-size: 2em;
  transition: color 0.2s; /* Smooth transition for color change */
}

.nav-link:hover {
  color: #f2f2f2; /* Adjusted hover color for visibility */
  text-shadow: 0 0 5px #fff; /* Optional: Adds a glow effect on hover */
}

.nav-item {
  position: relative; /* This ensures that the dropdown is positioned relative to the nav-item */
  display: inline-block; /* or your preferred display type */
}

.dropbtn {
  /* Style for your dropdown button */
  cursor: pointer;
  /* ... additional styles ... */
}

.dropdown-content {
  position: absolute;
  top: 100%; /* Below the nav-item */
  left: 0;
  background-color: #ffffff; /* Based on your image, it looks like a white background */
  box-shadow: 0 4px 8px rgba(0,0,0,0.1); /* Just an example for shadow */
  border-radius: 4px; /* Based on your image */
  width: 300px; /* Set a width for your dropdown */
  z-index: 100;
  display: none; /* Hide by default */
}

.nav-item:hover .dropdown-content {
  display: block; /* Show on hover or click */
}

.user-info {
  padding: 16px;
  display: flex;
  align-items: center;
  /* Add more styles here */
}

.avatar {
  border-radius: 50%; /* If you want a round avatar */
  margin-right: 8px;
  /* Set size of the avatar */
}

.username {
  font-weight: bold;
  /* Additional styles */
}

.notification {
  padding: 16px;
  background-color: #f3f4f6; /* Example color */
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.view-button {
  padding: 10px 20px; /* Adds space inside the button */
  background-color: #ffa500; /* Orange background color */
  color: white; /* Text color */
  border: none; /* No border */
  border-radius: 4px; /* Rounded corners */
  font-weight: bold; /* Makes the text bold */
  cursor: pointer; /* Changes cursor to pointer on hover */
  text-transform: uppercase; /* Uppercase text */
  font-size: 14px; /* Adjust font size as needed */
  letter-spacing: 1px; /* Spacing between letters */
  transition: background-color 0.3s ease; /* Smooth transition for hover effect */
  outline: none; /* Remove outline */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Optional: adds a subtle shadow */
}

.view-button:hover,
.view-button:focus {
  background-color: #e69500; /* Darker orange on hover/focus */
}

.view-button:active {
  background-color: #cc8400; /* Even darker orange when the button is clicked */
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2); /* Optional: makes the shadow smaller on click */
}

.dropdown-link {
  display: block;
  padding: 8px 16px;
  color: #3b3939; /* Example color */
  text-decoration: none;
}

.dropdown-link:hover {
  background-color: #eaeaea; /* Example hover background color */
}

.github-project a {
  display: inline-block;
  padding: 8px 16px;
  margin: 0 8px;
  background-color: #333; /* GitHub's color */
  color: #fff;
  text-align: center;
  border-radius: 4px;
  font-weight: bold;
  text-decoration: none;
  transition: background-color 0.2s ease;
}

.github-project a:hover,
.github-project a:focus {
  background-color: #545151; /* A lighter GitHub color for hover state */
  color: #fff;
  text-decoration: none; /* Ensures the text isn't underlined when hovered */
}

.github-project img {
  vertical-align: middle; /* Aligns the icon with the text */
  margin-right: 8px; /* Spacing between the icon and the text */
}

.github-logo {
  width: 40px; /* Adjust the size as needed */
  height: auto; /* This will maintain the aspect ratio */
  vertical-align: middle; /* To align the image with the text */
}
