.container-reconcile-content {
    margin: 1rem auto;
    padding: 20px;
    background-color: white;
    border-radius: 8px;
}

.reconcile-scrollable-table {
    max-height: 800px; /* Adjust this value as needed */
    overflow-y: auto;
    margin-bottom: 20px; /* Space at the bottom of the table */
  }
  
  .reconcile-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .reconcile-table, .reconcile-table th, .reconcile-table td {
    border: 1px solid #ddd;
  }
  
  .reconcile-table th, .reconcile-table td {
    text-align: left;
    padding: 8px;
  }
  
  .reconcile-table th {
    background-color: #f2f2f2;
    position: sticky;
    top: 0; /* Makes the header sticky */
    z-index: 10; /* Ensures the header is above the scrolled content */
  }
  
  .reconcile-table tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  /* Style for download sample link */
.download-sample {
    margin-left: 10px;
    text-decoration: none;
    color: #000;
    padding: 8px;
    border-radius: 4px;
    background-color: #f2f2f2;
    border: 1px solid #ddd;
  }
  
  .download-sample:hover {
    background-color: #e2e2e2;
  }
  
  .container-reconcile-content button {
    background-color: RGB(250, 135, 145);
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.container-reconcile-content button:hover {
    background-color: RGB(250, 135, 145);
}

.container-reconcile-content button:disabled {
    background-color: #ccc;
    cursor: default;
}
