.reconcile-past-days-container {
        max-width: 99%;
        margin: 20px auto;
        padding: 20px;
        background: #fff;
        border-radius: 8px;
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
      }
              
        .input-group {
          margin-bottom: 20px;
        }
        
        .days-input {
          padding: 10px;
          border: 1px solid #ddd;
          border-radius: 4px;
          margin-right: 10px;
        }
        
        .reconcile-button {
          padding: 10px 20px;
          border: none;
          background-color: RGB(248, 130, 46);
          color: white;
          border-radius: 4px;
          cursor: pointer;
          font-size: 16px;
        }
        
        .reconcile-button:hover {
          background-color: RGB(248, 130, 46);
        }
        
        .results-table {
          width: 100%;
          border-collapse: collapse;
          margin-top: 20px;
        }
        
        .results-table th,
        .results-table td {
          padding: 10px;
          border-bottom: 1px solid #ddd;
          text-align: left;
        }
        
        .results-table th {
          background-color: #f0f0f0;
        }
        
        .results-table tr:hover {
          background-color: #f2f2f2;
        }
        
        .error-message {
          color: red;
          margin-top: 20px;
        }
        