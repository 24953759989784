body {
  font-family: Arial, sans-serif;
  background-color: #f4f4f4;
  margin: 0;
  padding: 0;
}

.container {
  max-width: 1900px;
  width: 1000px; /* Ensures the container takes up the full width up to max-width */
  height: 1000px;
  margin: 0 auto;
  display: flex;
  padding: 10px;
  /* background-color: #ffffff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); */
  border-radius: 10px; /* Rounded corners for container */
  overflow: hidden; /* Ensures that inner content doesn’t overflow container's border-radius */
  flex-wrap: wrap; /* Allows items to wrap into the next line if there’s not enough space */
  flex-direction: row;
  justify-content: space-between;
}

.product-image,
.product-info {
  flex-basis: 100%;
  flex-grow: 1;
  flex-shrink: 0;
  overflow: hidden;
}

.product-image {
  flex: 2;
  display: flex;
  align-items: flex-start;
  width: 50%;
  height: 100%; /* Full height of the container */
  margin-right: 10px; /* Added margin-right */
}

.product-info {
  flex: 1;
  width: 40%; /* Adjust as needed */
  color: #333; /* General text color */
  background-color: #fff; /* Adding a background color */
  border-radius: 8px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
  padding: 15px;
  padding-right: 20px; /* Add padding to the right side */
}

.product-image-small,
.product-image-overview {
  height: 100%; /* 850px */
  flex-grow: 1; /* Allow them to grow and take the necessary space */
  border-radius: 10px;
  overflow: hidden; /* In case the content inside the divs overflow their boundaries */
}

.product-image-small {
  flex: 1;
  /* max-height: 850px; */
  border-radius: 10px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: rebeccapurple green; /* For Firefox */
  width: 30px; /* Adjust the width as necessary */
  flex-shrink: 0;
}

/* For Chrome, Safari, and Edge */
.product-image-small::-webkit-scrollbar {
  width: 5px; /* Width of the scrollbar */
}

.product-image-small::-webkit-scrollbar-thumb {
  background-color: rgba(188, 188, 188); /* Color of the slider */
}

.product-image-small img,
.product-image-overview img {
  display: block;
  max-width: 100%; /* Ensure images don’t overflow */
  height: 100%; /* Maintain aspect ratio */
  width: auto; /* Maintain aspect ratio */
  max-height: 100%; /* Ensure images don’t exceed the div height */
  margin: auto; /* Center images */
}

.product-image-small img {
  width: 40px; /* Adjust the width as necessary */
  height: auto;
  margin-bottom: 4px;
  cursor: pointer; /* Optional: for a click indicator */
  flex-direction: column; /* Stack images vertically */
  transition: border-color 0.3s ease;
}

.product-image-overview {
  flex: 3;
  width: 90%;
  padding-left: 6px;
  position: relative; /* Needed for absolute positioning relative to this container */
}

.product-image-overview img {
  width: 100%;
  height: auto;
  display: block;
  max-width: 100%;
  max-height: auto;
  margin: auto;
}

.product-info h1 {
  color: rgb(250, 135, 145); /* Applying the main color to the title */
  font-size: 2em; /* You can adjust as needed */
  margin-bottom: 20px;
}

.rating {
  display: inline-block;
  color: rgb(250, 135, 145); /* Applying the main color to the rating stars */
  margin: 10px 0;
  font-size: 1.2em; /* You can adjust as needed */
}

.description {
  margin: 20px 0;
  font-size: 1em; /* You can adjust as needed */
  line-height: 1.5; /* Improved readability */
}

.price {
  font-size: 3em;
  margin: 20px 0;
  color: #333;
}

.fixed-price-option,
.custom-price-option-wrapper {
  width: calc(100% - 6px); /* Subtracting the left and right margins */
  background-color: transparent; /* Make the background transparent */
  padding: 5px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Optional: Adds a shadow for better visibility */
  border-radius: 10px;
  margin-bottom: 20px; /* Space between the elements */
}

.fixed-price-option {
  display: flex;
  flex-direction: column;
  align-items: center; /* Horizontally centers all child elements */
  flex-wrap: wrap;
  justify-content: space-between; /* Distributes space between labels */
  gap: 5px; /* Space between each label */
  padding: 5px;
  margin-bottom: 10px; /* Space between this option and the next */
}

.fixed-price-option .option-label {
  display: flex;
  align-items: center;
  flex-wrap: wrap; /* Allows items to wrap to the next line */
}

.fixed-price-option .product-wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap; /* Allows items to wrap to the next line */
}

.fixed-price-option .product-description {
  margin-left: 10px; /* Space between image and text */
}

.custom-price-option-wrapper .custom-price-label {
  display: flex;
  padding: 20px;
  border-radius: 10px;
  margin-top: 0; /* Separation from previous elements */
  align-items: center;
}

.custom-price-option-wrapper input {
  margin-left: 10px; /* Space between label and input */
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px; /* Rounded corners for the input field */
}

.product-image-right {
  display: block;
  width: 120px; /* Adjusted size */
  height: auto;
  border: 3px solid transparent; /* Transparent border to keep the size consistent */
  transition: border-color 0.3s;
}

.product-options {
  display: flex;
  flex-direction: row;
  align-items: center; /* Center items horizontally */
  justify-content: flex-start;
}

.submit-order {
  width: 100%; /* Takes the full width of its container */
  padding: 15px 30px;
  border-radius: 50px;
  background-color: rgb(250, 135, 145);
  font-size: 1em;
  color: #ffffff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Optional shadow for depth */
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

.submit-order:hover {
  background-color: #555;
}

.option-label {
  display: flex;
  flex-direction: column;
  align-items: center; /* Horizontal centering */
  text-align: center; /* Centering the text inside the label */
  padding: 0px;
  border: 3px solid transparent;
  cursor: pointer;
  transition: border-color 0.3s;
}

.option-label input[type="radio"] {
  display: none;
}

.product-wrapper {
  display: flex;
  gap: 10px; /* Space between image and text */
  border: 3px solid transparent;
  transition: border-color 0.3s;
  flex-direction: column;
  align-items: center; /* Center items horizontally */
  text-align: center; /* Ensure text within this container is centered */
}

.option-label input[type="radio"]:checked + .product-wrapper {
  border-color: black; /* Black border when selected */
}

.product-description {
  display: block;
  text-align: center;
  font-weight: bold;
}
/* 
.option-label, .custom-price-option {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
} */

/* New styles for custom price option */
.custom-price-option {
  display: flex;
  flex-direction: column;
  align-items: center; /* Vertically center the custom price contents */
  padding: 0 10px; /* Add some horizontal padding */
}

.custom-price-container {
  display: flex;
  gap: 10px; /* Space between label and input */
  width: 100%; /* Take full width of the container */
  justify-content: center; /* Horizontally center the content */
}

.custom-price-label {
  font-size: 1em;
  font-weight: bold;
  color: #333;
}

input[type="text"] {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 1em;
}

#customPrice {
  flex-grow: 1; /* Allow input to take available space */
  padding: 5px; /* Some padding for visual comfort */
  border-radius: 5px; /* Rounded corners for input */
}

.product-image-small img:hover,
.product-image-small img.active {
  border-color: #333; /* Border color when hovered or active */
}

.product-image-overview-wrapper {
  position: relative; /* Anchor point for the absolute positioning of arrows */
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 3; /* Adjust based on your layout */
  width: 90%; /* Adjust based on your layout */
  height: 100%; /* or 850px if you have a fixed height */
  padding-left: 0px;
  margin: auto; /* To center it in the available space if it's inside a flex container */
}

.arrow-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: 2px solid transparent; /* Makes border invisible initially */
  border-radius: 50%; /* Makes the border circular */
  padding: 5px; /* Adjust the padding to size the circular area */
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease; /* Smooth transition for hover effect */
  cursor: pointer;
  z-index: 10; /* To make sure arrows are clickable above the image */
}

.arrow-button img {
  display: block;
}

.left-arrow {
  left: 10px;
}

.arrow-button:hover {
  border: 2px rgba(255, 255, 255, 0.5); 
  background-color: rgba(255, 255, 255, 0.5); /* Optional: slight white background */
}

.right-arrow {
  right: 10px;
}

@media (max-width: 768px) {
  .left-arrow,
  .right-arrow {
    left: 0; /* Adjust as necessary for the left arrow */
    right: 0; /* Adjust as necessary for the right arrow */
  }
}
