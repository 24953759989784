.financial-report-container {
  font-family: Arial, sans-serif;
}

.tab-bar {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.tab {
  border: none;
  border-radius: 20px;
  margin: 0 5px;
  padding: 10px 25px;
  cursor: pointer;
  background-color: #f3f3f3;
  color: #333;
  font-size: 16px;
  transition: all 0.3s ease;
}

.tab.active {
  background-color: #ff9500;
  color: white;
}

.date-picker-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.custom-datepicker {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.report-table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #ddd;
  margin-top: 20px;
}

.report-table th,
.report-table td {
  text-align: left;
  padding: 12px;
  border: 1px solid #ddd;
}

.report-table th {
  background-color: #ff9500;
  color: white;
}

.report-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
  .tab {
    padding: 8px 15px;
    font-size: 14px;
  }

  .report-table th,
  .report-table td {
    padding: 10px;
  }
}

thead th {
  white-space: nowrap; /* Prevent text from wrapping */
  padding: 10px; /* Add enough padding */
  text-align: left; /* Align text to the left */
  background-color: #f9f9f9; /* Give a background color */
  border-bottom: 1px solid #ddd; /* Add a border to the bottom for separation */
  font-weight: bold; /* Make the header text bold */
}
