.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.app-container {
  display: flex;
  min-height: 100vh; /* Ensure full height */
}

/* Sidebar styles */
.sidebar {
  width: 100px; /* Adjust width as needed */
  background-color: #ffffff; /* White background for sidebar */
  padding: 5px;
  box-sizing: border-box;
}

/* Styles for sidebar links */
.sidebar a {
  display: block;
  align-items: center;
  margin-bottom: 3px; /* Space between links */
  padding: 10px;
  text-decoration: none;
  color: #333; /* Dark text color for better readability */
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s;
}

/* Icon styles */
.sidebar a i {
  margin-right: 8px; /* Space between icon and text */
}

/* Adjust background and text color for the active/hover state */
.sidebar a:hover,
.sidebar a.active {
  color: #F8822E; /* Orange color for text on hover/active */
  background-color: #F0F0F0; /* Light grey background for hover/active state */
}

/* Specific style for the active link */
.sidebar a.active {
  background-color: #F8822E; /* Orange background for selected item */
  color: #fff; /* White text color for selected item */
}


/* Content styles */
.main-content {
  flex-grow: 1; /* Fill the remaining space */
  padding: 5px; /* 1em */
  overflow-y: auto; /* Add scroll for overflow */
  background-color: #fff; /* White background for content */
}

/* Ensure the .brand-container does not exceed the viewport width */
@media (max-width: 768px) {
  .sidebar {
    width: 100%; /* Full width for mobile */
    padding: 5px; /* Smaller padding for mobile */
  }
  .content {
    margin-top: 60px; /* Provide space for the sidebar when it's full width on mobile */
  }
  .brand-container {
    margin: 10px;
    border-radius: 0; /* Remove border-radius for mobile to fit screen */
  }
}
