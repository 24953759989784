/* Login container styles */
.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 50vh; 
    background-color: #f4f4f4; /* Light grey background */
    padding: 20px;
  }
  
  /* Login form styles */
  #login-form {
    max-width: 400px; /* Set a max-width for the form */
    width: 100%; /* Make it responsive */
    background: white;
    padding: 2em;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  /* Input field styles */
  #login-form input {
    width: 100%; /* Full width */
    padding: 10px;
    margin-bottom: 20px; /* Space between input fields */
    border: 1px solid #ccc; /* Light grey border */
    border-radius: 4px;
    box-sizing: border-box; /* Border included in width */
  }
  
  /* Input focus styles */
  #login-form input:focus {
    outline: none;
    border-color: #F8822E; /* Main color for focus outline */
    box-shadow: 0 0 0 2px rgba(248, 130, 46, 0.5); /* Soft glow effect */
  }
  
  /* Label styles */
  #login-form label {
    display: block;
    margin-bottom: 5px;
    color: #333; /* Dark grey color for text */
  }
  
  /* Button styles */
  #login-form button {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 4px;
    background-color: #0056b3; /* Main color for button */
    color: white;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: bold;
    transition: background-color 0.3s ease; /* Transition for a smooth color change */
  }
  
  /* Button hover and focus styles */
  #login-form button:hover,
  #login-form button:focus {
    background-color: #0056b3; /* A darker shade of the main color */
    outline: none; /* Remove focus outline */
  }

  .supplier-button {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .supplier-button:hover {
    background-color: #0056b3;
  }
  
  
/* form {
    margin: auto;
    width: 300px;
    padding: 1em;
    border: 1px solid #ccc;
    border-radius: 5px;
}

div {
    margin-bottom: 1em;
}

label {
    margin-bottom: .5em;
    color: #333333;
    display: block;
}

input {
    border: 1px solid #cccccc;
    border-radius: 4px;
    box-sizing: border-box;
    padding: 8px;
    width: 100%;
}

button {
    padding: 8px 15px;
    background: #007bff;
    border: none;
    border-radius: 5px;
    color: white;
    text-transform: uppercase;
} */
