.payment-success-container {
  font-family: 'Arial', sans-serif;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background: #f7f7f7;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.order-details-header {
  background: #fff;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.order-details-header h1 {
  color: #333;
  margin-bottom: 5px;
}

.order-details-header p {
  color: #666;
  font-size: 14px;
}

.shipping-and-item-container,
.payment-and-method-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.shipping-details,
.payment-details,
.payment-method {
  background: #fff;
  padding: 15px;
  border-radius: 8px;
  flex: 1;
  margin-right: 20px;
}

.shipping-details:last-child,
.payment-method:last-child {
  margin-right: 0;
}

.item-details {
  margin-bottom: 20px;
}

.item-details .item,
.product-item {
  background: #fff;
  padding: 15px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.item-image {
  width: 100px; /* or your preferred size */
  height: 100px;
  object-fit: cover;
  margin-right: 15px;
  border-radius: 4px;
}

.item-info h3,
.product-details p {
  margin: 5px 0;
  color: #333;
}

.item-info p,
.product-details p {
  color: #666;
  font-size: 14px;
}

.order-summary {
  background: #fff;
  padding: 15px;
  border-radius: 8px;
}

.order-summary h2 {
  color: #333;
  margin-bottom: 10px;
}

.order-summary p {
  color: #666;
  font-size: 14px;
  line-height: 1.5;
}

.place-order-button {
  text-align: center;
  margin-top: 20px;
}

.place-order-button button {
  background-color: #ff4500;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.place-order-button button:hover {
  background-color: #e03e00;
}



/* .payment-success-container {
  background: #fff;
  max-width: 900px;
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #e1e1e1;
  border-radius: 4px;
  font-family: 'Arial', sans-serif;
  color: #333;
}

.order-details-header h1 {
  color: #333;
  font-size: 24px;
  border-bottom: 1px solid #e1e1e1;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.shipping-and-item-container, .payment-and-method-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.shipping-details, .payment-details, .payment-method {
  background: #f7f7f7;
  padding: 15px;
  border-radius: 4px;
  flex-basis: 48%; 
}

.item-details {
  margin-bottom: 20px;
}

.item {
  display: flex;
  align-items: center;
  background: #f7f7f7;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 10px;
}

.item-image {
  max-width: 80px;
  max-height: 80px;
  margin-right: 15px;
  object-fit: cover;
}

.order-summary {
  background: #f7f7f7;
  padding: 15px;
  border-radius: 4px;
}

.order-summary h2 {
  margin-top: 0;
  margin-bottom: 15px;
  color: #333;
  font-size: 20px;
}

.detail, .total {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.total {
  font-weight: bold;
}

.submit-order-btn {
  display: block;
  width: 100%;
  background-color: #ff4500;
  color: #fff;
  border: none;
  padding: 12px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  text-align: center;
}

.submit-order-btn:hover {
  background-color: #e03e00;
}

@media (max-width: 768px) {
  .shipping-and-item-container, .payment-and-method-container {
    flex-direction: column;
  }

  .shipping-details, .payment-details, .payment-method {
    flex-basis: 100%;
    margin-bottom: 20px;
  }
} */
