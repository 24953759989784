/* CancelPage.css */

section {
    margin: 20px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
    text-align: center;
  }
  
  /* Add more styles as needed */
  