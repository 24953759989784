.product-main-page {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  justify-content: center;
  padding: 20px;
}

.load-more-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2px;
  margin-bottom: 40px; /* Add margin at the bottom to create a gap */
}

.load-more-btn {
  padding: 10px 20px;
  background-color: #f0f0f0; /* Light grey background */
  color: #333; /* Dark text color for contrast */
  font-weight: bold;
  border: none;
  border-radius: 20px; /* Rounded corners */
  cursor: pointer;
  outline: none;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
  transition: all 0.3s ease; /* Smooth transition for hover effects */
}

.load-more-btn:hover {
  background-color: #e0e0e0; /* Slightly darker on hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Larger shadow on hover */
}

.load-more-btn:active {
  transform: translateY(2px); /* Slight push effect when clicked */
}

.tab-container {
  display: flex;
  justify-content: start;
  border-bottom: 2px solid #ccc;
}

.tab-btn {
  padding: 10px 20px;
  border: none;
  border-bottom: 3px solid transparent;
  background-color: #f9f9f9;
  cursor: pointer;
  outline: none;
  transition: background-color 0.3s ease;
}

.tab-btn:hover {
  background-color: #e2e2e2;
}

.tab-btn.active {
  border-bottom-color: #ff0000; /* Active tab indicator color */
  font-weight: bold;
  background-color: white;
}
