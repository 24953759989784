.reconcile-container {
          max-width: 99%;
          margin: 2rem auto;
          padding: 1rem;
          background: #ffffff;
          border-radius: 8px;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        }
        
        .reconcile-input-group {
          margin-bottom: 1rem;
        }
        
        .reconcile-input {
          padding: 0.5rem;
          margin-right: 0.5rem;
          border: 1px solid #ddd;
          border-radius: 4px;
        }
        
        .reconcile-button {
          background-color: RGB(248, 130, 46);
          color: white;
          border: none;
          padding: 0.5rem 1rem;
          border-radius: 4px;
          cursor: pointer;
          font-size: 1rem;
        }
        
        .reconcile-button:hover {
          background-color: RGB(248, 130, 46);
        }
        
        .reconciliation-table {
          width: 100%;
          border-collapse: collapse;
          margin-top: 1rem;
        }
        
        .reconciliation-table th,
        .reconciliation-table td {
          text-align: left;
          padding: 12px;
          border-bottom: 1px solid #ddd;
        }
        
        .reconciliation-table th {
          background-color: RGB(248, 130, 46);
          color: white;
        }
        
        .reconciliation-table tr:nth-child(even) {
          background-color: #f2f2f2;
        }
        
        .reconcile-error {
          color: red;
          margin-top: 1rem;
        }
        